import paix_et_cohesion  from 'assets/images/paix_et_cohesion sociale.jpg';
import b_1 from 'assets/images/visite_banque_mondiale.jpeg'
import b_2 from 'assets/images/atelier3.jpeg'
import b_3 from 'assets/images/nutrition.jpeg'
import b_4 from 'assets/images/inspection_swedd.jpeg'
import b_5 from 'assets/images/mission.jpeg'
import b_6 from 'assets/images/franc.jpeg'
import sImg1  from 'assets/images/ong_eicf_price.jpg';
import sImg2  from 'assets/images/pjgouv.jpg';
import sImg3  from 'assets/images/banque_mondiale.jpg';
import sImg4 from 'assets/images/Partenariat.jpg'
import sImg5 from 'assets/images/ei_n.jpg'
import sImg6 from 'assets/images/paix_day_2.jpg'
const data_actu = [{
    id:1,
    image:b_1,
    titre:'Visite banque mondiale',
    mini_description:'Visite de supervision de la BANQUE MONDIALE dans le cadre du projet PMNDPE le 08 Mai 2023 à BOUNA',
    description:"Visite de supervision de la BANQUE MONDIALE dans le cadre du projet PMNDPE le 08 Mai 2023 à BOUNA",
    date:'08.12.2023',
    pdf:''
},
{
    id:2,
    image:b_2,
    titre:'Atelier sur le reporting',
    mini_description:"Atelier sur le reporting et la qualité des données organisé par EICF à l'hôtel SKY BLUE de Yamoussoukro le 08 Avril 2023.",
    description:"Visite de supervision de la BANQUE MONDIALE dans le cadre du projet PMNDPE le 08 Mai 2023 à BOUNA",
    date:'08.12.2023',
    pdf:''
},
{
    id:3,
    image:b_3,
    titre:'Sensibilisation nutrition',
    mini_description:'Sensibilisation communautaire en faveur de la Nutrition dans le département de PRIKRO.',
    description:"Visite de supervision de la BANQUE MONDIALE dans le cadre du projet PMNDPE le 08 Mai 2023 à BOUNA",
    date:'08.12.2023',
    pdf:''
},
{
    id:4,
    image:b_4,
    titre:"mission de l'inspection générale des finances",
    mini_description:"Dans le cadre du projet SWEDD ÉDUCATION, une mission de l'inspection générale des finances s'est rendue dans les régions du Bélier, Iffou et Gontougo",
    description:"Visite de supervision de la BANQUE MONDIALE dans le cadre du projet PMNDPE le 08 Mai 2023 à BOUNA",
    date:'08.12.2023',
    pdf:''
},
{
    id:5,
    image:b_5,
    titre:'Mission de la Directrice des Opérations de la Banque Mondiale',
    mini_description:"Compte rendu en images et vidéos de la Mission de la Directrice des Opérations de la Banque mondiale sur le Site de BANIA, tenue par L'ONG EICF.",
    description:"Visite de supervision de la BANQUE MONDIALE dans le cadre du projet PMNDPE le 08 Mai 2023 à BOUNA",
    date:'08.12.2023',
    pdf:''
},
{
    id:6,
    image:b_6,
    titre:"J-1:Travaux d'entretien du FRANC",
    mini_description:'Le directeur exécutif est envahi par la marmaille du FRANC',
    description:"Visite de supervision de la BANQUE MONDIALE dans le cadre du projet PMNDPE le 08 Mai 2023 à BOUNA",
    date:'08.12.2023',
    pdf:''
},

{
    id:7,
    image:sImg2,
    titre:"LANCEMENT DU PJ GOUV",
    mini_description:"LANCEMENT DU PJ GOUV, UN PROGRAMME AMBITIEUX POUR L’ACCÉLÉRATION DE LA FORMATION, DE L’INSERTION PROFESSIONNELLE ET DE LA PROMOTION DE L’ENTREPRENEURIAT DES JEUNES.",
    description:"EICF, partenaire de mise en œuvre de L'AEJ (Agence Emploi Jeunes) et du BCP-Emploi, représentée par son Directeur Exécutif, a pris part au lancement du PJ GOUV aujourd'hui 15 Juin 2023 au palais de la culture de Treicheville. Un projet en cohérence avec la mise en œuvre de la politique publique du Gouvernement dénommée Programme Jeunesse du Gouvernement (PJ Gouv), d’un coût global de 1 118 milliards de FCFA et qui s’étend sur la période 2023-2025.",
    date:'08.12.2023',
    pdf:''
}
,

{
    id:8,
    image:sImg1,
    titre:"Prix  du Meilleur Promoteur des Œuvres Sociales ",
    mini_description:"LANCEMENT DU PJ GOUV, UN PROGRAMME AMBITIEUX POUR L’ACCÉLÉRATION DE LA FORMATION, DE L’INSERTION PROFESSIONNELLE ET DE LA PROMOTION DE L’ENTREPRENEURIAT DES JEUNES.",
    description:"Du 22 au 23 Mai 2023 s’est tenue à Dubai la 6ème édition du prestigieux AFRICA DUBAI BUSINESS AWARDS, en abrégé ADBA. Cet événement qui se décline en un forum, des B to-B, une visite touristique et commerciale, avec en point d’orgue une cérémonie de remise de prix baptisée « Diner-Gala de Distinction des Grands Leaders Internationaux » a vu la participation de EICF comme organisation récipiendaire.  Cette cérémonie visait à récompenser les Meilleurs Promoteurs des Œuvres Sociales en Afrique. ",
    date:'08.12.2023',
    pdf:''
},
{
    id:9,
    image:sImg3,
    titre:"EICF invitée par la Banque Mondiale",
    mini_description:`EICF invitée par la Banque Mondiale  a pris part à la présentation du Rapport sur le Développement dans le Monde 2023 de la Banque mondiale, intitulé "Migrants, réfugiés et sociétés" qui a eu lieu à son siège à Cocody le Jeudi 15 Juin 2023.`,
    description:"Ce rapport offre un cadre solide pour l'élaboration de politiques en matière de migration et d'asile, en identifiant les approches unilatérales et plurilatérales ainsi que les responsabilités multilatérales nécessaires. Il constitue une occasion unique de débattre des enjeux migratoires et d'explorer des solutions innovantes. ",
    date:'08.12.2023',
    pdf:''
},
{
    id:10,
    image:sImg4 ,
    titre:"Cérémonie de signature des Subventions organisée par l’Ambassade des USA en Côte d’Ivoire le jeudi 7 Décembre 2023",
    mini_description:`EICF est bénéficiaire du Fonds de Résilience de l’Ambassadeur pour financer  le projet intitulé :<< Engagement des Jeunes et des Femmes pour la prévention des crises, la stabilité sociale, la consolidation de la paix et la réduction de la violence communautaire dans la région du Bounkani >>...`,
    description:"EICF est bénéficiaire du Fonds de Résilience de l’Ambassadeur pour financer  le projet intitulé :<< Engagement des Jeunes et des Femmes pour la prévention des crises, la stabilité sociale, la consolidation de la paix et la réduction de la violence communautaire dans la région du Bounkani >>. Ce projet compte toucher 500 bénéficiaires directs et 1000 bénéficiaires indirects dont les jeunes et femmes âgés de 18 à 40 ans à travers 12 OBC des 4 départements de la région du Bounkani (Bouna, Doropo, Nassian, Teheni). Nos remerciements vont à l’endroit de SEMme l’Ambassadeur des USA en Côte d’Ivoire et aux peuples américains pour leur appui financier et technique .Ensemble, tous engagés pour la Paix et la Prospérité des Peuples.",
    date:'07.12.2023',
    pdf:''
},
{
id:11,
image:sImg5,
titre:"Sommet de la Jeunesse pour la Paix et un Avenir  Durable ",
mini_description:`EICF a été invitée à participer au Sommet de la Jeunesse pour la Paix et un Avenir  Durable ( Youth Peace Summit and Sustainable Future) organisé par l’Institut IPPDR ( Institute of Public Policy and Diplomacy Research) du 22 au 26 Juillet 2024 qui s’est tenu à Montpellier en France...`,
description:`EICF a été invitée à participer au Sommet de la Jeunesse pour la Paix et un Avenir  Durable ( Youth Peace Summit and Sustainable Future) organisé par l’Institut IPPDR ( Institute of Public Policy and Diplomacy Research) du 22 au 26 Juillet 2024 qui s’est tenu à Montpellier en France. 
Ce sommet avait réuni plusieurs jeunes, experts,  ONG et membres de la société civile  de plus de 50 pays du monde et avait pour objectif de promouvoir des stratégies de progrès et d’explorer davantage l’ODD 16 Paix, Justice et Institutions efficaces. 
EICF représentée par son Directeur Exécutif  a pris part à tous les ateliers et tables rondes sur les thématiques liées à la culture de la Paix, à la résolution des conflits, à la justice sociale et a apporté ses contributions pertinentes pour la modification de la Résolution 2250 de la Charte des Nations Unies. 
Au cours de ce Sommet, EICF a aussi participé au pannel portant  sur les nouveaux mécanismes pour un développement plus durable de la Paix,  au cours duquel nous avons partagé  nos expériences en matière de culture de la Paix, de prévention de conflits et de gestion post-conflits à travers des projets financés par l’Etat de Côte d’Ivoire, le PNUD, l’ambassade des USA en CI et d’autres partenaires financiers. 
Ce sommet a été l’occasion pour nous de démontrer le rôle  primordial des Jeunes et des Femmes pour une Paix plus durable dans le monde et en particulier en Afrique et dans notre pays la Côte d’Ivoire. 
Sans Paix, il n y a pas de Développement et sans Développement, il n y a pas de Paix Durable. 
Restons tous engagés pour une culture plus durable de la Paix afin d’assurer un Avenir certain pour les futures générations.`,
date:'02.08.2024',
pdf:''
},
{
    id:12,
    image:sImg6,
    titre:"Journée Internationale des Droits de l’Homme  ",
    mini_description:`Construire un Monde où les Droits de l’Homme sont respectés pour un avenir meilleur, c’est maintenant.
Ensemble, tous engagés pour garantir les droits fondamentaux de l’homme pour une Paix Durable en Afrique et partout dans le Monde....`,
    description:`Cette reconnaissance témoigne de son engagement exceptionnel envers les efforts humanitaires et de son leadership visionnaire qui a profondément marqué d’innombrables vies. Son dévouement à l’amélioration de l’humanité et son leadership exemplaire ont de lui un candidat vraiment méritant pour cet honneur.
Tout au long de sa remarquable carrière, il a travaillé sans relâche pour faire progresser les droits de l’homme, favoriser la justice sociale et apporter un soutien crucial aux populations vulnérables. Son approche innovante du leadership humanitaire, qui combine l'élaboration de politiques stratégiques et l'activisme populaire, a généré un changement durable et significatif dans diverses communautés. Son travail percutant illustre sa capacité à susciter des changements significatifs, guidé par sa compréhension approfondie des politiques publiques et sa quête incessante d’équité et de justice. Ses efforts ont démontré sa capacité unique à relever des défis complexes avec compassion, résilience et vision à long terme.
Au-delà de ses réalisations professionnelles, il est un mentor et une source d'inspiration pour de nombreuses personnes, plaidant constamment en faveur d'approches inclusives et participatives dans toutes ses initiatives. Son style de leadership empathique et son engagement à responsabiliser les autres sont des qualités essentielles pour créer un changement durable et favoriser la paix et le développement. Cette nomination reconnaît ses contributions exceptionnelles au leadership humanitaire.
En son nom et au nom de l’ONG EICF, M. le Directeur Exécutif remercie l’IPPDR et à sa tête Son Excellence l’Ambassadrice Dr Andrise Bass pour la confiance et pour tout l’honneur accordé à sa personne.
M. le Directeur Exécutif dédie cette distinction au Président Fondateur de l’ONG EICF, M. Brou N’Guessan Albert, à tout le personnel de EICF, à tous ses partenaires techniques et financiers et à toutes les communautés vulnérables bénéficiaires.
Ensemble, construisons une Paix véritable pour un Développement plus Durable...`,
    date:'10.12.2024',
    pdf:''
    }


]

export default data_actu.reverse();